<template>
<div id="page-content-wrapper" class="doscroll">
  <Header :showBack="true" :title="'Edit Layout Dashboard ['+dashId+'] '+dashboardName"/>
  <div
    v-if="isWxccDashboardAdmin ||  allowedEditors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase()) || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
    class="bv-example-row text-left pl-4 pt-4">
    <b-col cols="12" sm="12" md="12" lg="10" xl="10">
      <b-form-group
          id="fieldset-horizontal1"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label=""
          label-for="marquee"
      >
        <b-button @click.prevent="saveDashboardLayout" variant="warning" :disabled="errorGettingLayout || currentWidgets.length === 0">Save Layout</b-button>
      </b-form-group>
    <hr/>
      <b-form-group
          id="fieldset-horizontal2"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Selected Widgets"
          label-for="widgets"
      >
      <b-form-checkbox-group
        id="widgets"
        v-model="currentWidgets"
        :options="widgets"
        name="widgets"
      ></b-form-checkbox-group>
      <p class="font-italic"><b-badge>Note</b-badge>: restore layout on the browsers using this dashboard if widgets have changed</p>
      <!-- <b-form-select id="widgets" v-model="currentWidgets" :options="widgets" multiple :select-size="8"></b-form-select> -->
      </b-form-group>
      <hr/>
      <b-form-group
          id="fieldset-horizontal3"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Scrolling Message"
          label-for="marquee"
      >
        <b-form-input v-model="message" id="marquee"></b-form-input>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal4"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Scrolling Speed (sec)"
          label-for="marqueetime"
      >
        <b-form-input v-model="marqueeTime" id="marqueetime" type="number"></b-form-input>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal5"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Scrolling Message Color"
          label-for="marqueecolor"
      >
        <b-form-input v-model="marqueeFontColor" id="marqueecolor" type="text"></b-form-input>
        <b-link target="_blank" href="https://www.w3schools.com/tags/ref_colornames.asp">color names reference</b-link> (use the name (i.e. AliceBlue) or hex (i.e. #aabbcc) value)
      </b-form-group>
      <hr/>
      <b-form-group
          id="fieldset-horizontal6"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Logo (URL)"
          label-for="logo"
      >
        <b-form-input v-model="logo" id="logo" type="url"></b-form-input>
      </b-form-group>
       <hr/>
       <b-alert variant="warning" :show="cwvaluewarning <= cwvalueinfo">
        Warning: Important must be higher than Info
       </b-alert>
       <b-alert variant="warning" :show="cwvalueurgent <= cwvaluewarning">
        Warning: Urgent must be higher than Important
       </b-alert>
       <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13a"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Calls Waiting Threshold Levels"
          label-for="colorfontinfo"
        >
        <b-input-group prepend="Info">
          <b-form-input v-model.number="cwvalueinfo" id="cwvalueinfo" type="number"></b-form-input>
          <b-input-group-prepend is-text>
            Important:
          </b-input-group-prepend>
          <b-form-input v-model.number="cwvaluewarning" id="cwvaluewarning" type="number"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent:
          </b-input-group-prepend>
          <b-form-input v-model.number="cwvalueurgent" id="cwvalueurgent" type="number"></b-form-input>
        </b-input-group>
      </b-form-group>
        <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13b"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Calls Waiting Threshold Font Colors"
          label-for="colorfontinfo"
        >
        <b-input-group :prepend="'Info (>' + cwvalueinfo + '):'">
          <b-form-input v-model="colorfontinfo" id="colorfontinfo" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Important (>{{ cwvaluewarning }}):
          </b-input-group-prepend>
          <b-form-input v-model="colorfontwarning" id="colorfontwarning" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent (>{{ cwvalueurgent }}):
          </b-input-group-prepend>
          <b-form-input v-model="colorfonturgent" id="colorfonturgent" type="text"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13c"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Calls Waiting Threshold Background Colors"
          label-for="colorbackgroundinfo"
        >
        <b-input-group :prepend="'Info (>' + cwvalueinfo + '):'">
          <b-form-input v-model="colorbackgroundinfo" id="colorbackgroundinfo" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Important (>{{ cwvaluewarning }}):
          </b-input-group-prepend>
          <b-form-input v-model="colorbackgroundwarning" id="colorbackgroundwarning" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent (>{{ cwvalueurgent }}):
          </b-input-group-prepend>
          <b-form-input v-model="colorbackgroundurgent" id="colorbackgroundurgent" type="text"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-alert variant="warning" :show="lwvaluewarning <= lwvalueinfo">
        Warning: Important must be higher than Info
       </b-alert>
       <b-alert variant="warning" :show="lwvalueurgent <= lwvaluewarning">
        Warning: Urgent must be higher than Important
       </b-alert>
      <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13ab"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Longest Waiting Threshold Levels"
          label-for="colorfontinfo"
        >
        <b-input-group prepend="Info">
          <b-form-input v-model.number="lwvalueinfo" id="lwvalueinfo" type="number"></b-form-input>
          <b-input-group-prepend is-text>
            Important:
          </b-input-group-prepend>
          <b-form-input v-model.number="lwvaluewarning" id="lwvaluewarning" type="number"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent:
          </b-input-group-prepend>
          <b-form-input v-model.number="lwvalueurgent" id="lwvalueurgent" type="number"></b-form-input>
        </b-input-group>
      </b-form-group>
        <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13d"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Longest Waiting Threshold Font Colors"
          label-for="lwcolorfontinfo"
        >
        <b-input-group :prepend="'Info (>' + lwvalueinfo + 's):'">
          <b-form-input v-model="lwcolorfontinfo" id="lwcolorfontinfo" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Important (>{{ lwvaluewarning }}s):
          </b-input-group-prepend>
          <b-form-input v-model="lwcolorfontwarning" id="lwcolorfontwarning" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent (>{{ lwvalueurgent }}s):
          </b-input-group-prepend>
          <b-form-input v-model="lwcolorfonturgent" id="lwcolorfonturgent" type="text"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13e"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Longest Waiting Threshold Background Colors"
          label-for="lwcolorbackgroundinfo"
        >
        <b-input-group :prepend="'Info (>' + lwvalueinfo + 's):'">
          <b-form-input v-model="lwcolorbackgroundinfo" id="lwcolorbackgroundinfo" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Important  (>{{ lwvaluewarning }}s):
          </b-input-group-prepend>
          <b-form-input v-model="lwcolorbackgroundwarning" id="lwcolorbackgroundwarning" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent  (>{{ lwvalueurgent }}s):
          </b-input-group-prepend>
          <b-form-input v-model="lwcolorbackgroundurgent" id="lwcolorbackgroundurgent" type="text"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-alert variant="warning" :show="gosvaluewarning >= gosvalueinfo">
        Warning: Important must be lower than Info
       </b-alert>
       <b-alert variant="warning" :show="gosvalueurgent >= gosvaluewarning">
        Warning: Urgent must be lower than Important
       </b-alert>
       <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13ac"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="GOS Threshold Levels"
          label-for="colorfontinfo"
        >
        <b-input-group prepend="Info">
          <b-form-input v-model.number="gosvalueinfo" id="gosvalueinfo" type="number"></b-form-input>
          <b-input-group-prepend is-text>
            Important:
          </b-input-group-prepend>
          <b-form-input v-model.number="gosvaluewarning" id="gosvaluewarning" type="number"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent:
          </b-input-group-prepend>
          <b-form-input v-model.number="gosvalueurgent" id="gosvalueurgent" type="number"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13f"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="GOS Threshold Font Colors"
          label-for="goscolorfontinfo"
        >
        <b-input-group :prepend="'Info (&lt;' + gosvalueinfo + '%):'">
          <b-form-input v-model="goscolorfontinfo" id="goscolorfontinfo" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Important (&lt;{{ gosvaluewarning }}%):
          </b-input-group-prepend>
          <b-form-input v-model="goscolorfontwarning" id="goscolorfontwarning" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent (&lt;{{ gosvalueurgent }}%):
          </b-input-group-prepend>
          <b-form-input v-model="goscolorfonturgent" id="goscolorfonturgent" type="text"></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13g"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="GOS Threshold Background Colors"
          label-for="goscolorbackgroundinfo"
        >
        <b-input-group :prepend="'Info (&lt;' + gosvalueinfo + '%):'">
          <b-form-input v-model="goscolorbackgroundinfo" id="goscolorbackgroundinfo" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Important (&lt;{{ gosvaluewarning }}%):
          </b-input-group-prepend>
          <b-form-input v-model="goscolorbackgroundwarning" id="goscolorbackgroundwarning" type="text"></b-form-input>
          <b-input-group-prepend is-text>
            Urgent (&lt;{{ gosvalueurgent }}%):
          </b-input-group-prepend>
          <b-form-input v-model="goscolorbackgroundurgent" id="goscolorbackgroundurgent" type="text"></b-form-input>
        </b-input-group>
              <b-link target="_blank" href="https://www.w3schools.com/tags/ref_colornames.asp">color names reference</b-link> (use the name (i.e. AliceBlue) or hex (i.e. #aabbcc) value)

      </b-form-group>
       <hr/>
       <b-form-group
          id="fieldset-horizontal7"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Selected Queue Columns"
          label-for="qColumns"
      >
        <b-input-group-append>
          <b-form-checkbox-group
            id="qColumns"
            v-model="currentQueueColumns"
            :options="qColumns"
            name="queueColumns"
          ></b-form-checkbox-group>
          <b-button variant="info" v-b-modal.modal-3>info</b-button>
        </b-input-group-append>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal8"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Queue Sort"
          label-for="queuesort"
      >
        <b-form-select id="queuesort" v-model="queueSort" :options="queueSortOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal9"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Queue Sort Direction"
          label-for="queuesortdir"
      >
        <b-form-select id="queuesortdir" v-model="queueSortDirection" :options="directionOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal8b"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Queue Name Align"
          label-for="queuenamealign"
      >
        <b-form-select id="queunamealign" v-model="queuenamealign" :options="alignOptions"></b-form-select>
      </b-form-group>

      <hr/>
       <b-form-group
          id="fieldset-horizontal7e"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Selected Email Queue Columns"
          label-for="eColumns"
      >
      <b-form-checkbox-group
        id="eColumns"
        v-model="currentEmailColumns"
        :options="eColumns"
        name="emailColumns"
      ></b-form-checkbox-group>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal8e"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Email Queue Sort"
          label-for="emailsort"
      >
        <b-form-select id="emailsort" v-model="emailSort" :options="emailSortOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal9e"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Email Queue Sort Direction"
          label-for="emailsortdir"
      >
        <b-form-select id="emailsortdir" v-model="emailSortDirection" :options="directionOptions"></b-form-select>
      </b-form-group>
      <hr/>
       <b-form-group
          id="fieldset-horizontal7c"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Selected Chat Queue Columns"
          label-for="cColumns"
      >
      <b-form-checkbox-group
        id="cColumns"
        v-model="currentChatColumns"
        :options="cColumns"
        name="chatColumns"
      ></b-form-checkbox-group>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal8c"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Chat Queue Sort"
          label-for="chatsort"
      >
        <b-form-select id="chatsort" v-model="chatSort" :options="chatSortOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal9c"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Chat Queue Sort Direction"
          label-for="chatsortdir"
      >
        <b-form-select id="chatsortdir" v-model="chatSortDirection" :options="directionOptions"></b-form-select>
      </b-form-group>
      <hr/>
      <b-form-group
          id="fieldset-horizontal10"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Agent Sort"
          label-for="agentsort"
      >
        <b-input-group-append>
          <b-form-select id="agentsort" v-model="agentSort" :options="agentSortOptions"></b-form-select>
          <b-button variant="info" v-b-modal.modal-2>info</b-button>
        </b-input-group-append>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal11"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Agent Sort Direction"
          label-for="agentsortdir"
      >
        <b-form-select id="agentsortdir" v-model="agentSortDirection" :options="directionOptions"></b-form-select>
      </b-form-group>
       <b-form-group
          id="fieldset-horizontal10"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Agent Sort 2"
          label-for="agentsort2"
      >
        <b-input-group-append>
          <b-form-select id="agentsort2" v-model="agentSort2" :options="agentSortOptions"></b-form-select>
          <b-button variant="info" v-b-modal.modal-2>info</b-button>
        </b-input-group-append>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal11"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Agent Sort 2 Direction"
          label-for="agentsortdir2"
      >
        <b-form-select id="agentsortdir2" v-model="agentSortDirection2" :options="directionOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal11"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Show Agent Calls Handled"
          label-for="agentcallshandled"
      >
        <b-form-select id="agentcallshandled" v-model="showAgentCallsHandled" :options="truefalseOptions"></b-form-select>
      </b-form-group>

      <b-form-group
          id="fieldset-horizontal11b"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Show Agent Extension (Agents Table widget only)"
          label-for="agentextension"
      >
        <b-form-select id="agentextension" v-model="showAgentExtension" :options="truefalseOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal11e"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Show Agent Team (Agents Table widget only)"
          label-for="agentteam"
      >
        <b-form-select id="agentteam" v-model="showAgentTeam" :options="truefalseOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        id="fieldset-horizontal11d"
        label-cols-sm="4"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="7"
        label="Show QueueName on Talking"
        label-for="showQueueNameOnTalking"
      >

        <b-form-select id="showQueueNameOnTalking" v-model="showQueueNameOnTalking" :options="truefalseOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        id="fieldset-horizontal11c"
        label-cols-sm="4"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="7"
        label="Show QueueName on Wrapup"
        label-for="showQueueNameOnWrapup"
      >

        <b-form-select id="showQueueNameOnWrapup" v-model="showQueueNameOnWrapup" :options="truefalseOptions"></b-form-select>
      </b-form-group>
      <b-form-group
          id="fieldset-horizontal12"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Hide Agents with NotReady Reason Codes"
          label-for="hidenr"
      >
        <b-form-tags input-id="hidenr" v-model="hideNR" placeholder="Enter Reason Code"></b-form-tags>
      </b-form-group>
      <hr/>
      <b-form-group
          v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
          id="fieldset-horizontal13"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Allowed Layout Editors"
          label-for="allowedEditors"
      >
              <b-input-group-append>

        <b-form-tags input-id="allowedEditors" v-model="allowedEditors" placeholder="Enter Allowed Editor Email Addresses"></b-form-tags>
        <b-button variant="info" v-b-modal.modal-1>info</b-button>
        </b-input-group-append>
      </b-form-group>
       <b-form-group
          v-if="isWxccDashboardAdmin"
          id="fieldset-horizontal14"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Allowed Supervisors"
          label-for="allowedSupervisors"
      >
        <b-input-group-append>
        <b-form-tags input-id="allowedSupervisors" v-model="allowedSupervisors" placeholder="Enter Allowed Supervisor Email Addresses"></b-form-tags>
        <b-button variant="info" v-b-modal.modal-1>info</b-button>
        </b-input-group-append>
        </b-form-group>
      <p
        v-if="isWxccDashboardAdmin || allowedSupervisors.map(e => e.toLowerCase()).includes(userEmail.toLowerCase())"
        class="font-italic"><b-badge>Note</b-badge>: these users need to be registered on the Atea system and assigned to this Customer</p>
    </b-col>
  </div>
  <div
    v-else
    class="bv-example-row text-left pl-4 pt-4">
    You have no permission to edit the layout for this dashboard
  </div>
  <div>
  <b-modal id="modal-1" size="lg" :hide-footer="true">
    <template #modal-title><span class="text-dark">Roles</span></template>
    <img src="@/assets/roles.png"/>
  </b-modal>
  <b-modal id="modal-2" size="lg" :hide-footer="true">
    <template #modal-title><span class="text-dark">Agent Sort Options</span></template>
    <img src="@/assets/state-statecode.png"/>
  </b-modal>
  <b-modal id="modal-3" size="lg" :hide-footer="true">
    <template #modal-title><span class="text-dark">Queue Column Descriptions</span></template>
    <img src="@/assets/qcols.png"/>
  </b-modal>
</div>
</div>
</template>
<script>
import GraphqlService from '../service/GraphqlService'
import { mapGetters } from 'vuex'
import Header from '@/modules/shared/components/elements/Header'

export default {
  data () {
    return {
      dashId: '',
      dashboardName: '',
      message: '',
      loading: true,
      errorGettingLayout: false,
      marqueeTime: 0,
      marqueeFontColor: '',
      widgets: [
        { value: 'gs-queue', text: 'Calls Waiting' },
        { value: 'gs-oldest', text: 'Longest Waiting' },
        { value: 'gs-totalcalls', text: 'Total Calls Handled' },
        { value: 'gs-avgwait', text: 'Avg WaitTime' },
        { value: 'gs-gos', text: 'Grade Of Service' },
        { value: 'gs-abandondcalls', text: 'Total Abandoned %' },
        { value: 'gs-queues', text: 'Queues' },
        { value: 'gs-agents', text: 'Agents Grid' },
        { value: 'gs-graph', text: 'Calls Today (graph)' },
        { value: 'gs-waitgraph', text: 'Wait Times Today (graph)' },
        { value: 'gs-marquee', text: 'Scrolling Message' },
        { value: 'gs-agentlist', text: 'Agents Table' },
        { value: 'gs-emailqueues', text: 'Email Queues' },
        { value: 'gs-chatqueues', text: 'Chat Queues' }
      ],
      currentWidgets: [],
      logo: '',
      queueSort: '',
      emailSort: '',
      chatSort: '',
      queueSortOptions: ['name', 'callswaiting', 'longestwaiting', 'tasksoffered', 'taskshandled'],
      queueSortDirection: '',
      emailSortOptions: ['name', 'callswaiting', 'longestwaiting', 'tasksoffered', 'taskshandled'],
      emailSortDirection: '',
      chatSortOptions: ['name', 'callswaiting', 'longestwaiting', 'tasksoffered', 'taskshandled'],
      chatSortDirection: '',
      directionOptions: ['asc', 'desc'],
      truefalseOptions: ['true', 'false'],
      agentSort: '',
      agentSort2: '',
      agentSortOptions: ['stateCode', 'stateCodeAndIdleCode', 'state', 'stateAndIdleCode', 'displayName', 'duration'],
      agentSortDirection: '',
      agentSortDirection2: '',
      hideNR: [],
      currentQueueColumns: [],
      qColumns: [
        { value: 'callswaiting', text: 'Calls Waiting' },
        { value: 'longestwaiting', text: 'Longest Waiting' },
        { value: 'loggedinagents', text: 'Logged In Agents' },
        { value: 'availableagents', text: 'Available Agents (only for non-skills based queue team)' },
        { value: 'connectedagents', text: 'Talking Agents' },
        { value: 'tasksoffered', text: 'Calls Presented' },
        { value: 'taskshandled', text: 'Calls Handled' },
        { value: 'tasksabandoned', text: 'Calls Abandoned' },
        { value: 'tasksshort', text: 'Calls Short' },
        { value: 'taskstotal', text: 'Calls Total' },
        { value: 'avghandletime', text: 'Avg TalkTime' },
        { value: 'avgenqueuetime', text: 'Avg WaitTime' },
        { value: 'gos', text: 'Grade of Service' }
      ],
      allowedEditors: [],
      allowedSupervisors: [],
      showAgentCallsHandled: 'true',
      showAgentExtension: 'false',
      showAgentTeam: 'false',
      currentEmailColumns: [],
      eColumns: [
        { value: 'callswaiting', text: 'Email Waiting' },
        { value: 'longestwaiting', text: 'Longest Waiting' },
        { value: 'loggedinagents', text: 'Logged In Agents' },
        { value: 'availableagents', text: 'Available Agents (only for non-skills based queue team)' },
        { value: 'connectedagents', text: 'Talking Agents' },
        { value: 'tasksoffered', text: 'Emails Presented' },
        { value: 'taskshandled', text: 'Emails Handled' },
        { value: 'tasksabandoned', text: 'Emails Abandoned' },
        { value: 'taskstotal', text: 'Emails Total' },
        { value: 'avghandletime', text: 'Avg HandleTime' },
        { value: 'avgenqueuetime', text: 'Avg WaitTime' },
        { value: 'gos', text: 'Grade of Service' }
      ],
      currentChatColumns: [],
      cColumns: [
        { value: 'callswaiting', text: 'Chats Waiting' },
        { value: 'longestwaiting', text: 'Longest Waiting' },
        { value: 'loggedinagents', text: 'Logged In Agents' },
        { value: 'availableagents', text: 'Available Agents (only for non-skills based queue team)' },
        { value: 'connectedagents', text: 'Talking Agents' },
        { value: 'tasksoffered', text: 'Chats Presented' },
        { value: 'taskshandled', text: 'Chats Handled' },
        { value: 'tasksabandoned', text: 'Chats Abandoned' },
        { value: 'taskstotal', text: 'Chats Total' },
        { value: 'avghandletime', text: 'Avg HandleTime' },
        { value: 'avgenqueuetime', text: 'Avg WaitTime' },
        { value: 'gos', text: 'Grade of Service' }
      ],
      colorfontinfo: '',
      colorbackgroundinfo: '',
      colorfontwarning: '',
      colorbackgroundwarning: '',
      colorfonturgent: '',
      colorbackgroundurgent: '',
      lwcolorfontinfo: '',
      lwcolorbackgroundinfo: '',
      lwcolorfontwarning: '',
      lwcolorbackgroundwarning: '',
      lwcolorfonturgent: '',
      lwcolorbackgroundurgent: '',
      goscolorfontinfo: '',
      goscolorbackgroundinfo: '',
      goscolorfontwarning: '',
      goscolorbackgroundwarning: '',
      goscolorfonturgent: '',
      goscolorbackgroundurgent: '',
      queuenamealign: 'center',
      alignOptions: ['center', 'left', 'right'],
      showQueueNameOnWrapup: 'false',
      showQueueNameOnTalking: 'false',
      cwvalueinfo: 0,
      cwvaluewarning: 2,
      cwvalueurgent: 5,
      lwvalueinfo: 0,
      lwvaluewarning: 30,
      lwvalueurgent: 60,
      gosvalueinfo: 90,
      gosvaluewarning: 85,
      gosvalueurgent: 70
    }
  },
  async mounted () {
    this.dashId = this.$route.params.id
    try {
      const dash = await GraphqlService.getDashboard(this.$store, this.dashId, true)
      this.dashboardName = dash.name
      this.message = dash.message
      this.marqueeTime = dash.marqueetime
      this.marqueeFontColor = dash.marqueefontcolor
      this.currentWidgets = dash.widgets
      this.queueSort = dash.queuesort
      this.queueSortDirection = dash.queuesortdirection
      this.emailSort = dash.emailsort
      this.emailSortDirection = dash.emailsortdirection
      this.chatSort = dash.chatsort
      this.chatSortDirection = dash.chatsortdirection
      this.agentSort = dash.agentsort
      this.agentSort2 = dash.agentsort2
      this.agentSortDirection = dash.agentsortdirection
      this.agentSortDirection2 = dash.agentsortdirection2
      this.logo = dash.logo
      this.hideNR = dash.hidenr
      this.currentQueueColumns = dash.queuetablecolumns
      this.currentEmailColumns = dash.emailtablecolumns
      this.currentChatColumns = dash.chattablecolumns
      this.allowedEditors = dash.allowededitors
      this.allowedSupervisors = dash.allowedsupervisors
      this.showAgentCallsHandled = dash.showagentcallshandled
      this.showAgentExtension = dash.showagentextension
      this.showAgentTeam = dash.showagentteam
      this.loading = false
      this.colorfontinfo = dash.colorfontinfo
      this.colorbackgroundinfo = dash.colorbackgroundinfo
      this.colorfontwarning = dash.colorfontwarning
      this.colorbackgroundwarning = dash.colorbackgroundwarning
      this.colorfonturgent = dash.colorfonturgent
      this.colorbackgroundurgent = dash.colorbackgroundurgent
      this.lwcolorfontinfo = dash.lwcolorfontinfo
      this.lwcolorbackgroundinfo = dash.lwcolorbackgroundinfo
      this.lwcolorfontwarning = dash.lwcolorfontwarning
      this.lwcolorbackgroundwarning = dash.lwcolorbackgroundwarning
      this.lwcolorfonturgent = dash.lwcolorfonturgent
      this.lwcolorbackgroundurgent = dash.lwcolorbackgroundurgent
      this.goscolorfontinfo = dash.goscolorfontinfo
      this.goscolorbackgroundinfo = dash.goscolorbackgroundinfo
      this.goscolorfontwarning = dash.goscolorfontwarning
      this.goscolorbackgroundwarning = dash.goscolorbackgroundwarning
      this.goscolorfonturgent = dash.goscolorfonturgent
      this.goscolorbackgroundurgent = dash.goscolorbackgroundurgent
      this.queuenamealign = dash.queuenamealign
      this.showQueueNameOnWrapup = dash.showqueuenameonwrapup
      this.showQueueNameOnTalking = dash.showqueuenameontalking
      this.cwvalueinfo = dash.cwvalueinfo
      this.cwvaluewarning = dash.cwvaluewarning
      this.cwvalueurgent = dash.cwvalueurgent
      this.lwvalueinfo = dash.lwvalueinfo
      this.lwvaluewarning = dash.lwvaluewarning
      this.lwvalueurgent = dash.lwvalueurgent
      this.gosvalueinfo = dash.gosvalueinfo
      this.gosvaluewarning = dash.gosvaluewarning
      this.gosvalueurgent = dash.gosvalueurgent
    } catch (err) {
      this.errorGettingLayout = true
      console.log(err)
      this.$store.dispatch('global/DO_TOAST', { message: 'Error Getting Dashboard Layout', variant: 'danger' })
    }
  },
  methods: {
    async saveDashboardLayout () {
      try {
        const dash = await GraphqlService.saveDashboardLayout(
          this.$store,
          this.dashId,
          {
            currentWidgets: this.currentWidgets,
            message: this.message,
            marqueeTime: this.marqueeTime,
            marqueeFontColor: this.marqueeFontColor,
            queueSort: this.queueSort,
            queueSortDirection: this.queueSortDirection,
            emailSort: this.emailSort,
            emailSortDirection: this.emailSortDirection,
            chatSort: this.chatSort,
            chatSortDirection: this.chatSortDirection,
            agentSort: this.agentSort,
            agentSortDirection: this.agentSortDirection,
            agentSort2: this.agentSort2,
            agentSortDirection2: this.agentSortDirection2,
            logo: this.logo,
            hideNR: this.hideNR,
            currentQueueColumns: this.currentQueueColumns,
            currentEmailColumns: this.currentEmailColumns,
            currentChatColumns: this.currentChatColumns,
            allowedEditors: this.allowedEditors,
            allowedSupervisors: this.allowedSupervisors,
            showAgentCallsHandled: this.showAgentCallsHandled,
            showAgentExtension: this.showAgentExtension,
            showAgentTeam: this.showAgentTeam,
            colorfontinfo: this.colorfontinfo,
            colorbackgroundinfo: this.colorbackgroundinfo,
            colorfontwarning: this.colorfontwarning,
            colorbackgroundwarning: this.colorbackgroundwarning,
            colorfonturgent: this.colorfonturgent,
            colorbackgroundurgent: this.colorbackgroundurgent,
            lwcolorfontinfo: this.lwcolorfontinfo,
            lwcolorbackgroundinfo: this.lwcolorbackgroundinfo,
            lwcolorfontwarning: this.lwcolorfontwarning,
            lwcolorbackgroundwarning: this.lwcolorbackgroundwarning,
            lwcolorfonturgent: this.lwcolorfonturgent,
            lwcolorbackgroundurgent: this.lwcolorbackgroundurgent,
            goscolorfontinfo: this.goscolorfontinfo,
            goscolorbackgroundinfo: this.goscolorbackgroundinfo,
            goscolorfontwarning: this.goscolorfontwarning,
            goscolorbackgroundwarning: this.goscolorbackgroundwarning,
            goscolorfonturgent: this.goscolorfonturgent,
            goscolorbackgroundurgent: this.goscolorbackgroundurgent,
            queuenamealign: this.queuenamealign,
            showqueuenameonwrapup: this.showQueueNameOnWrapup,
            showqueuenameontalking: this.showQueueNameOnTalking,
            cwvaluewarning: this.cwvaluewarning,
            cwvalueurgent: this.cwvalueurgent,
            cwvalueinfo: this.cwvalueinfo,
            lwvaluewarning: this.lwvaluewarning,
            lwvalueurgent: this.lwvalueurgent,
            lwvalueinfo: this.lwvalueinfo,
            gosvaluewarning: this.gosvaluewarning,
            gosvalueurgent: this.gosvalueurgent,
            gosvalueinfo: this.gosvalueinfo
          }
        )
        if (dash.id !== this.dashId) {
          this.$store.dispatch('global/DO_TOAST', { message: 'Error Saving Dashboard Layout', variant: 'danger' })
        } else {
          this.$store.dispatch('global/DO_TOAST', { message: 'Saved Dashboard Layout, users/tv\'s will need to reload the page', variant: 'success' })
        }
      } catch (err) {
        console.error('error', err)
        this.$store.dispatch('global/DO_TOAST', { message: 'Error Saving Dashboard Layout', variant: 'danger' })
      }
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    isWxccDashboardAdmin () {
      return this.roles.split(',').filter(e => e === 'wxcc_dashboard_admin').length === 1
    },
    userEmail () {
      return this.$auth.user.email
    }
  },
  components: {
    Header
  }
}
</script>
<style scoped>
#page-content-wrapper {
  background-color: white;
  color: black;
}
.doscroll {
  overflow: auto;
}
</style>
